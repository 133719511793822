import React from 'react';

import Layout from '../Layout';
import PasswordSetupForm from './PasswordSetUpForm';

function PasswordSetup() {

  return (
    <Layout>
      <PasswordSetupForm />
    </Layout>
  );
}

export default PasswordSetup;
