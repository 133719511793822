import React, { useState } from 'react';
import Layout from '../Layout';
import SignInForm from './SignInForm';

function SignIn() {

  return (
    <Layout>
      <SignInForm />
    </Layout>
  );
}

export default SignIn;
