import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { publicFetch } from '../../../utils/apiHelper';
import { saveSession } from '../../../utils/sessionHelper';
import { validateEmail } from '../../../utils/stringHelper';
import FormInputSignin from '../../../components/Form/FormInputSignin';

function SignInForm({ currentBG }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    await publicFetch(
      'POST',
      'user',
      '/token/',
      'Les informations de connexion sont erronées. Veuillez réessayer.',
      JSON.stringify({
        username: email,
        password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        saveSession(response);
        navigate('/');
      }
    });
  };

  const passwordIsValid = () => {
    return password.length > 7;
  };

  const emailIsValid = () => {
    return validateEmail(email);
  };

  const recoverPasswordButton = (
    <span
      className="cursor-pointer hover:text-custom-orange"
      onClick={() => navigate('/recover-password')}>
      Mot de passe oublié ?
    </span>
  );


  return (
    <div className="flex flex-col gap-y-8 ml-36 w-[500px] mb-8 z-10 bg-white rounded-md p-[32px]">
      <span className="text-3xl font-semibold">Connexion à votre compte</span>
      <div className="flex flex-col gap-6">
        <FormInputSignin label={'Adresse mail'} required isValid={emailIsValid()}>
          <InputText
            id="email"
            type="text"
            placeholder="Entrez votre adresse email"
            className="w-full custom-text-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ '--hover-border-color': currentBG }}
          />
        </FormInputSignin>

        <FormInputSignin
          label={'Mot de passe'}
          required
          isValid={passwordIsValid()}
          helpMessage={recoverPasswordButton}>
          <InputText
            id="password"
            type="password"
            placeholder="Entrez votre mot de passe"
            className="w-full custom-text-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ '--hover-border-color': currentBG }}

          />
        </FormInputSignin>
      </div>

      <Button
        label="Se connecter"
        icon="pi pi-user"
        className="bg-white text-black custom-button border-black font-semibold mt-4"
        loading={loading}
        disabled={!passwordIsValid() || !emailIsValid()}
        onClick={handleLogin}
        style={{ '--hover-button-color': currentBG, opacity: !passwordIsValid() || !emailIsValid() ? 0.4 : 1 }}

      />
    </div>
  );
}

export default SignInForm;
