import React from 'react';
import Layout from '../Layout';
import RecoverPasswordForm from './RecoverPasswordForm';

function RecoverPassword() {

  return (
    <Layout>
      <RecoverPasswordForm />
    </Layout>
  );
}

export default RecoverPassword;
