import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { publicFetch } from '../../../utils/apiHelper';
import FormInputSignin from '../../../components/Form/FormInputSignin';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { useNavigate, useParams } from 'react-router-dom';
import { Message } from 'primereact/message';

function PasswordSetupForm({ currentBG }) {
  const [password, setPassword] = useState('');
  const [passwordBis, setPasswordBis] = useState('');
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      fetchAccountDetail(token);
    } else {
      console.log('Error: token not found');
    }
  }, []);

  const fetchAccountDetail = async (token) => {
    await publicFetch('GET', 'user', `/user/${token}/`).then((res) => {
      setAccount(res);
    });
  };

  const handlePasswordSetup = async () => {
    setLoading(true);
    await publicFetch(
      'PUT',
      'user',
      `/user/${token}/`,
      'Une erreur est survenue. Veuillez réessayer.',
      JSON.stringify({
        password: password
      })
    ).then((response) => {
      if (!response) {
        setLoading(false);
      } else {
        setDialogOpen(true);
        setTimeout(() => {
          navigate('/');
        }, 5000);
      }
    });
  };

  const passwordIsValid = () => {
    return password.length > 7;
  };

  const passwordBisIsValid = () => {
    return passwordBis.length > 7 && passwordBis === password;
  };

  return (
    <div className="flex flex-col gap-y-8 ml-36 w-[500px] mb-8 z-10 bg-white rounded-md p-[32px]">
      <span className="text-3xl font-semibold">Création du mot de passe</span>
      {account && (
        <div className="flex flex-col gap-6">
          <FormInputSignin label={'Adresse mail'} required isValid={true}>
            <InputText
              disabled
              readOnly
              id="email"
              type="text"
              placeholder="Entrez votre adresse email"
              className="w-full custom-text-input"
              value={account?.email}
              style={{ '--hover-border-color': currentBG }}
            />
          </FormInputSignin>

          <FormInputSignin label={'Mot de passe'} required isValid={passwordIsValid()}>
            <InputText
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              className="w-full custom-text-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ '--hover-border-color': currentBG }}
            />
          </FormInputSignin>

          <FormInputSignin
            label={'Répéter le mot de passe'}
            required
            isValid={passwordBisIsValid()}>
            <InputText
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              className="w-full custom-text-input"
              value={passwordBis}
              onChange={(e) => setPasswordBis(e.target.value)}
              style={{ '--hover-border-color': currentBG }}
            />
          </FormInputSignin>
        </div>
      )}

      {account ? (
        <Button
          label="Valider mon mot de passe"
          icon="pi pi-lock"
          className="bg-white text-black custom-button border-black font-semibold btn mt-4"
          loading={loading}
          disabled={!account || !passwordIsValid() || !passwordBisIsValid()}
          onClick={handlePasswordSetup}
          style={{ '--hover-button-color': currentBG, opacity: (!account || !passwordIsValid() || !passwordBisIsValid()) ? 0.4 : 1 }}
        />
      ) : (
        <Message severity="error" text={`Le lien est erroné.`} />
      )}

      <Dialog
        header="Configuration terminée"
        visible={dialogOpen}
        style={{ width: '30vw' }}
        draggable={false}
        closable={false}>
        <div className="flex flex-row gap-x-5 items-center mb-5">
          <div style={{ width: 30, marginRight: 15 }}>
            <ProgressSpinner style={{ width: '30px', height: '30px' }} />
          </div>
          <p>Vous allez être redirigé vers la page de connexion...</p>
        </div>
      </Dialog>
    </div>
  );
}

export default PasswordSetupForm;
